import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const CupsIllustration = (props: IIconProps) => {
  const { width = 257, height = 127, className } = props;

  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 257 127"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.149 43.929s-2.79 5.663 7.387 3.037c10.178-2.627 44.405-28.318 33.488-34.145"
        className="fillWhite"
        fill="#fff"
      />
      <path
        d="M17.149 43.929s-2.79 5.663 7.387 3.037c10.178-2.627 44.405-28.318 33.488-34.145"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.648 47.459s44.57 34.39 44.898 33.898c.328-.492 8.864 1.396 20.684-17.729L59.01 19.962s-16.663 21.34-36.362 27.497z"
        className="fillBlack"
        fill="#000"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.15 43.929s7.387-19.042 33.406-29.549C76.575 3.874 26.096 49.757 17.149 43.93zM210.774 36.542c7.305-6.32 28.728.246 42.107 22.49 11.983 19.863-25.199 9.85-40.055-6.649-4.186-4.596-6.484-11.983-2.052-15.841z"
        className="fillWhite"
        fill="#fff"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M212.416 39.907c3.693-1.313 29.631.82 38.659 27.25l-31.846 39.234-28.318-17.319 21.505-49.165z"
        className="fillBlack"
        fill="#000"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M190.911 88.99s4.76-5.17 19.371 5.172c14.61 10.342 8.618 13.625 7.223 14.281-5.007 2.381-34.802-11.162-26.594-19.453z"
        className="fillWhite"
        fill="#fff"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.231 72.985s28.646 12.886 34.228 33.324c5.581 20.438-11.984 1.642 1.887-10.506 19.043-16.744 34.638 32.175 30.862 29.466-11.819-8.7 52.695-3.939 58.605-25.198"
        stroke="#000"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 26.5l9.5 5.5m3-31l8 13.5M37.5 1v10"
        stroke="#000"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default React.memo(CupsIllustration);
