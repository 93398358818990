import React from 'react';

import ErrorIcon from '../../images/Error';

import style from './Input.scss';

import cn from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';

export interface ITextareaProps {
  className?: string;
  name: string;
  placeholder?: string;
  value?: string;
  hasError?: boolean;
  readOnly?: boolean;
  label?: string;
  helperText?: string;
  maxLength?: number;
  onChange: (input: { name: string; value: string }) => void;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  isAutoFocus?: boolean;
  isDisabled?: boolean;
}

export interface ITextareaOnChange {
  name: string;
  value: string;
}

export function Textarea(props: ITextareaProps) {
  const {
    name = '',
    value = '',
    placeholder,
    label,
    onFocus,
    onBlur,
    onChange,
    maxLength,
    helperText,
    className,
    isDisabled,
    hasError,
    isAutoFocus = false,
  } = props;

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    if (typeof onChange === 'function') {
      const textarea: ITextareaOnChange = { name, value };
      onChange(textarea);
    }
  };

  return (
    <div
      className={cn(className, style.wrapper, {
        [style.error]: hasError,
        [style.hasValue]: !!props.value,
        [style.hasLabel]: !!props.label,
      })}
    >
      <TextareaAutosize
        id={name}
        name={name}
        value={value}
        placeholder={placeholder}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={handleTextareaChange}
        maxLength={maxLength}
        disabled={isDisabled}
        autoFocus={isAutoFocus}
      />
      <label htmlFor={name}>{label}</label>
      {hasError ? (
        <>
          <ErrorIcon className={style.errorIcon} />
          <span className={style.errorMessage}>{helperText}</span>
        </>
      ) : (
        ''
      )}
    </div>
  );
}
