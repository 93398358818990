import React from 'react';

import { isEmail } from '../../utils/helpers';
import { PrimaryButton } from '../Buttons/PrimaryButton';
import { ContentAnimate } from '../ContentAnimate/ContentAnimate';
import { IInputOnChange, Input } from '../Inputs/Input';
import { ITextareaOnChange, Textarea } from '../Inputs/Textarea';

import style from './Contact.scss';

import axios from 'axios';

interface ISignUpProps {
  className?: string;
  btnText?: string;
}

interface IPayload {
  [name: string]: string;
}

interface IInvalidFields {
  [name: string]: boolean;
}

interface ISignUpBannerState {
  payload: IPayload;
  invalidFields: IInvalidFields;
  errorMsg: string;
  isSending: boolean;
  isSubmitted: boolean;
}

const requiredFields: string[] = ['name', 'email', 'message'];

class ContactForm extends React.Component<ISignUpProps, ISignUpBannerState> {
  constructor(props: ISignUpProps) {
    super(props);
    this.state = {
      // request payload
      errorMsg: '',
      invalidFields: {},
      isSending: false,
      isSubmitted: false,
      payload: {},
    };
  }

  handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { isSending, payload } = this.state;
    if (isSending) {
      return;
    }

    // Validate fields
    const invalidFields: IInvalidFields = requiredFields.reduce(
      (o, name: string) => ({ ...o, [name]: !payload[name] }),
      {}
    );
    if (requiredFields.some((name) => invalidFields[name])) {
      this.setState({ invalidFields, errorMsg: 'All fields are required.' });
      return;
    }
    if (!isEmail(payload.email)) {
      this.setState({
        errorMsg: 'Invalid email.',
        invalidFields: { email: true },
      });
      return;
    }

    this.setState({ isSending: true, errorMsg: '' });

    const url = process.env.CONTACT_US_ENDPOINT || '';

    axios
      .post(url, JSON.stringify(payload))
      .then((res) => {
        this.setState({ isSubmitted: true, isSending: false, payload: {} });
      })
      .catch((err) => {
        this.setState({ errorMsg: err.message, isSending: false });
      });
  };

  handleChange = (input: IInputOnChange | ITextareaOnChange) => {
    const { payload, invalidFields } = this.state;
    this.setState({
      invalidFields: { ...invalidFields, [input.name]: false },
      payload: { ...payload, [input.name]: input.value },
    });
  };

  render() {
    const { btnText } = this.props;
    const { errorMsg, invalidFields, isSubmitted, isSending } = this.state;
    const { name = '', email = '', message = '' } = this.state.payload;

    return (
      <>
        {isSubmitted ? (
          <>
            <ContentAnimate
              className={style.contactAnimate}
              inViewAnimationClass={style.contactInView}
            >
              <h2 className={style.responseTitle}>
                We’ll contact <br className="d-none d-md-block" />
                you shortly!
              </h2>
            </ContentAnimate>
            <ContentAnimate
              className={style.contactAnimate}
              inViewAnimationClass={style.contactInView}
            >
              <p>
                Thank you for getting in touch with us. We value you time and will be contacting you
                as soon as possible.
              </p>
            </ContentAnimate>
          </>
        ) : (
          <>
            <ContentAnimate
              className={style.contactAnimate}
              inViewAnimationClass={style.contactInView}
            >
              <h1>Let’s talk</h1>
            </ContentAnimate>
            <ContentAnimate
              className={style.contactAnimate}
              inViewAnimationClass={style.contactInView}
            >
              <form className={style.form} onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-12">
                    <Input
                      value={name}
                      name="name"
                      className={style.inputWrapper}
                      onChange={this.handleChange}
                      label="Your Name"
                      helperText={errorMsg}
                      hasError={!!errorMsg && invalidFields.name}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-12">
                    <Input
                      value={email}
                      name="email"
                      className={style.inputWrapper}
                      onChange={this.handleChange}
                      label="Email Adress"
                      helperText={errorMsg}
                      hasError={!!errorMsg && invalidFields.email}
                    />
                  </div>
                  <div className="col-12">
                    <Textarea
                      value={message}
                      name="message"
                      className={style.inputWrapper}
                      onChange={this.handleChange}
                      label="Your Message"
                      helperText={errorMsg}
                      hasError={!!errorMsg && invalidFields.message}
                    />
                  </div>
                  <div className="col-12">
                    <PrimaryButton type="submit" isLoading={isSending}>
                      {btnText || 'Join Us'}
                    </PrimaryButton>
                  </div>
                </div>
              </form>
            </ContentAnimate>
          </>
        )}
      </>
    );
  }
}

export default ContactForm;
