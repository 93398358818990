import React from 'react';

import { ContentAnimate } from '../components/ContentAnimate/ContentAnimate';
import ContactForm from '../components/Forms/Contact';
import Layout from '../components/layout';
import SEO from '../components/seo';
import CupsIllustrationImage from '../images/Contact/cups-illustration';

import style from '../styles/pages/contact.scss';

import cn from 'classnames';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const ContactPage = (props: any) => {
  const { data } = props;

  return (
    <Layout>
      <SEO title="Contact" />
      <section className={style.hero}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-6 col-xl-5">
              <ContentAnimate inViewAnimationClass={style.illustrationInView}>
                <CupsIllustrationImage className={cn('d-lg-none', style.illustrationImage)} />
              </ContentAnimate>
              <ContactForm />
            </div>
            <div className="col-12 col-lg-5 offset-lg-1">
              <ContentAnimate inViewAnimationClass={style.heroInView}>
                <Img
                  className={cn('lazy d-none d-lg-block', style.bigImage)}
                  fluid={data.bigImage.childImageSharp.fluid}
                  alt="code"
                  loading="eager"
                />
              </ContentAnimate>
              <ContentAnimate inViewAnimationClass={style.illustrationInView}>
                <CupsIllustrationImage
                  className={cn('d-none d-lg-block', style.illustrationImage)}
                />
              </ContentAnimate>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
const DefaultLayoutSettingsQuery = (props: any) => {
  const renderDefaultLayoutSettings = (data: any) => <ContactPage data={data} {...props} />;

  return (
    <StaticQuery
      query={graphql`
        query ContactSection {
          bigImage: file(relativePath: { eq: "Contact/hero-login.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={renderDefaultLayoutSettings}
    />
  );
};

export default DefaultLayoutSettingsQuery;
