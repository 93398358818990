/*
 * Warning! The following functions can't be used
 * inside .render() method. They rely on the fact that
 * window and all its properties are defined.
 */

export function scrollToTop() {
  const topOfWindow = document.documentElement.scrollTop || document.body.scrollTop;

  if (topOfWindow > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, topOfWindow - topOfWindow / 5);
  }
}

export function scrollDown() {
  const topOfWindow = document.documentElement.scrollTop || document.body.scrollTop;

  if (topOfWindow < 700) {
    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: 800,
    });
  }
}

export function isMobile(): boolean {
  if (typeof navigator !== 'undefined' && typeof navigator.userAgent !== 'undefined') {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }
  return false;
}

export function isEmail(str: string | null | undefined) {
  return !!str && /^[^@\s]+@[^\s]+\.[a-zA-Z]+$/.test(str) === true;
}

export function validPhoneNumber(str: string | null | undefined) {
  return !!str && /^\([2-9]\d{2}\)\s[2-9]\d{2}-\d{4}$/.test(str.toString()) === true;
}
